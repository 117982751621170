/* eslint-disable formatjs/no-literal-string-in-jsx */
/* eslint-disable no-restricted-imports */
import FeatureGates from '@atlaskit/feature-gate-js-client';
import DiscoverFilledGlyph from '@atlaskit/icon/glyph/discover-filled';
import { MoreAtlassianAppsIcon } from '@atlaskit/temp-nav-app-icons/more-atlassian-apps';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { usePostOfficeContext } from '@atlassian/post-office-context';
import { useFireCrossFlowAnalyticsEvents } from '@atlassian/surface-analytics';
import { SectionContainer, Skeleton, SwitcherThemedItem } from '@atlassian/switcher/primitives';
import { Journeys, useCrossFlow } from '@atlassiansox/cross-flow-support';
import { css } from '@compiled/react';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { isVisualRefresh, useIsWacContext } from '@post-office/growth-common';
import {
	type ErrorBoundariedProps,
	createPlacementComponent,
	useInitialData,
} from '@post-office/placement-common';
import { type ComponentType, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { ProductDismissalFlag, useProductDismissalFlag } from './flag';
import { type Message } from './message-renderer';
import { SwitcherSkeletonItem } from './skeleton-item';
import { initializeMessageRenderer } from '../__generated__/client/render';
import { withAppSwitcherDiscoverySectionIntlProvider } from '../__generated__/intl-provider';
import type { AppSwitcherDiscoverySectionInitialData } from '../api/types';

const adminSwitcherItemStyle = css({
	backgroundColor: token('color.background.neutral', colors.N30),
	color: token('color.text.subtlest', colors.DN90),
	display: 'flex',
	width: token('space.400', '32px'),
	height: token('space.400', '32px'),
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: token('border.radius.200', '8px'),
	overflow: 'hidden',
});

const discoverMoreProductsTitle = (
	<FormattedMessage
		id="app-switcher-discovery-section.discover-more-products"
		description="Header for discovery section when there are no recommendations"
		defaultMessage="Discover more apps"
	/>
);

const recommendedForYourTeamTitle = (
	<FormattedMessage
		id="app-switcher-discovery-section.recommended-for-your-team"
		description="Header for discovery section when there are 1 or more recommendations"
		defaultMessage="Recommended for your team"
	/>
);

const moreAtlassianProducts = (
	<FormattedMessage
		id="app-switcher-discovery-section.more-atlassian-products"
		description="Button indicating that clicking on it will open the product store"
		defaultMessage="More Atlassian apps"
	/>
);

const CROSS_FLOW_SOURCE_COMPONENT = 'atlassian-switcher';
const CROSS_FLOW_SOURCE_CONTEXT = 'more';

const PLACEMENT_ID = 'app-switcher-discovery-section';

// Jira Local Consumption - MessageRenderer is typed as 'React.FC<any>' as otherwise it fails typecheck within Jira with the below error:
// The inferred type of 'MessageRenderer' cannot be named without a reference to '@post-office/placement-common/node_modules/react-error-boundary'. This is likely not portable. A type annotation is necessary.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Type '(props: any) => ReactNode' is not assignable to type 'FC<any>'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MessageRenderer: React.FC<any> = initializeMessageRenderer();

type AppSwitcherDiscoverySectionPlacementProps = {
	initialData: Promise<Response | undefined> | undefined;
	extraAnalyticsAttributes?: Record<string, string>;
};

export const AppSwitcherDiscoverySectionPlacementComponent: ComponentType<
	ErrorBoundariedProps<AppSwitcherDiscoverySectionPlacementProps>
> = withAppSwitcherDiscoverySectionIntlProvider(
	createPlacementComponent(
		PLACEMENT_ID,
		({ initialData, extraAnalyticsAttributes }: AppSwitcherDiscoverySectionPlacementProps) => {
			const initialDataState = useInitialData<AppSwitcherDiscoverySectionInitialData>(initialData);
			const crossFlow = useCrossFlow();
			const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();
			const isInWacContext = useIsWacContext();

			const handleClickMoreAtlassianProducts = useCallback(async () => {
				if (crossFlow.isEnabled) {
					fireAnalyticsEvent({
						eventType: 'ui',
						action: 'clicked',
						actionSubject: 'listItem',
						actionSubjectId: 'discoverMoreAtlassianProductsButton',
					});
					await crossFlow.api.open({
						journey: Journeys.DISCOVER,
						sourceComponent: CROSS_FLOW_SOURCE_COMPONENT,
						sourceContext: CROSS_FLOW_SOURCE_CONTEXT,
					});
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []);
			const [dismissedMessageInstanceIds, setDismissedMessageInstanceIds] = useState<string[]>([]);
			const { dismissedMessageIds, showFlag, handleDismissFlag } = useProductDismissalFlag();
			const { fireViewedUIEvent } = useFireCrossFlowAnalyticsEvents();
			const { isSiteAdmin } = usePostOfficeContext();

			const handleOnDismissed = useCallback(
				(dismissedMessageInstanceId: string) => {
					setDismissedMessageInstanceIds([
						...dismissedMessageInstanceIds,
						dismissedMessageInstanceId,
					]);
					showFlag(dismissedMessageInstanceId);
				},
				[dismissedMessageInstanceIds, showFlag],
			);

			useEffect(() => {
				if (initialDataState.data?.messages) {
					const productIds = (initialDataState.data.messages as Message[])
						.map((message: Message) => getProductIdFromMessageTemplateId(message.messageTemplateId))
						.filter(Boolean) as string[];

					fireViewedUIEvent(
						{
							action: 'viewed',
							actionSubject: 'section',
							actionSubjectId: 'atlassianSwitcherDiscoverProducts',
						},
						{
							source: 'atlassianSwitcherDiscoverProductsSection',
							recommendedProductIds: productIds,
						},
						{ isSiteAdmin },
					);
				}
			}, [fireViewedUIEvent, initialDataState.data?.messages, isSiteAdmin]);

			if (initialDataState.error) {
				return null;
			}

			if (initialDataState.isLoading) {
				return <Skeleton />;
			}

			const messages = initialDataState?.data.messages as Message[];

			const messagesWithoutDismissed = messages.filter(
				(message) => !dismissedMessageInstanceIds.includes(message.messageInstanceId),
			);

			const decideTitle = () => {
				if (isInWacContext && messagesWithoutDismissed.length === 0) {
					return null;
				}
				return messagesWithoutDismissed.length > 0
					? recommendedForYourTeamTitle
					: discoverMoreProductsTitle;
			};

			const title = decideTitle();
			// GALILEO-1402 START
			// check if there is a recommendation for JSM or JPD
			const hasJsmOrJpdRecommendation = messagesWithoutDismissed.some(
				(message) =>
					message.messageTemplateId === 'recommendation-jsm' ||
					message.messageTemplateId === 'recommendation-jpd',
			);
			// Fire exposure event ONLY if there is a recommendation for JSM or JPD
			hasJsmOrJpdRecommendation &&
				FeatureGates.getExperimentValue(
					'recommend_templates_from_switcher_for_jpd_and_jsm',
					'recommendation',
					'product',
				);
			// GALILEO-1402 END
			return (
				<SectionContainer sectionId="discover" title={title}>
					{messagesWithoutDismissed.map((message) => (
						<MessageRenderer
							key={message.messageInstanceId}
							{...message}
							onDismissed={handleOnDismissed}
							loadingFallback={<SwitcherSkeletonItem />}
							extraAnalyticsAttributes={extraAnalyticsAttributes}
						/>
					))}
					{isInWacContext ? null : (
						<SwitcherThemedItem
							onClick={handleClickMoreAtlassianProducts}
							icon={
								<span css={adminSwitcherItemStyle}>
									{isVisualRefresh() ? (
										MoreAtlassianAppsIcon({
											label: 'Discover More',
											size: '32',
											testId: 'app-switcher-discover-more-section__icon',
										})
									) : (
										<DiscoverFilledGlyph label={'Discover more'} />
									)}
								</span>
							}
						>
							{moreAtlassianProducts}
						</SwitcherThemedItem>
					)}
					<ProductDismissalFlag
						dismissedMessageIds={dismissedMessageIds}
						handleDismissFlag={handleDismissFlag}
					/>
				</SectionContainer>
			);
		},
		{
			choreographer: {
				isDefaultEnabled: false,
			},
		},
	),
);

const MESSAGE_TEMPLATE_ID_TO_PRODUCT_ID_MAP: Record<string, string> = {
	'recommendation-atlas': '7c917705-866d-4b6b-b266-0fc7633e8342',
	'recommendation-confluence': '6aab3f4e-cc71-474d-8d28-abef8b057808',
	'recommendation-jpd': 'a6f908c8-709c-45f4-9e2d-eca25b93b94a',
	'recommendation-jsm': 'de63a248-1f93-46ed-b6ea-ab8e38af1c88',
	'recommendation-jsw': 'd8a847a4-cde4-4c50-8ea1-dc3d4193214f',
	'recommendation-jwm': '46ccaa9e-cdb0-4995-8886-5827a5ff162b',
	'recommendation-loom': '7683a73e-423d-41a1-b43f-d497656f8b5d',
	'recommendation-opsgenie': 'fdb4649d-ca4d-49f8-a8bf-ffa558a65551',
	'recommendation-rovo': '91ae3e3f-138e-43be-98cc-48df03d13c32',
};

// This is a temporary mapping for this placement to contribute to go/crossflowfunnel
// It will be fixed in the future by updating the dashboard to read directly from PO analytics
const getProductIdFromMessageTemplateId = (messageTemplateId: string) => {
	return MESSAGE_TEMPLATE_ID_TO_PRODUCT_ID_MAP[messageTemplateId];
};

export default AppSwitcherDiscoverySectionPlacementComponent;
